import client from './../apiService';
import {IConfiguration}  from "@/interfaces/MTConfiguration"

class MTCongigurationService{
    POGRNSettingUpdate(model :Array<IConfiguration>){
        const url=`MTConfiguration/POGRNSettingUpdate`;
        return client.post(url,model, {
            headers:{
                'Content-Type':'application/json'
            }
        });
    }
    getPOGRNFields(){
        const url= `MTConfiguration/GetPOGRNField`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }
        })
    }

}

export default new MTCongigurationService();