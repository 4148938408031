












































































































































































import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import { ITableHeader } from "@/interfaces/common";
import { IConfiguration } from "@/interfaces/MTConfiguration";
import DataTable from "@/components/common/Tables/DataTable.vue";
import ConfigurationHeader from "@/components/MTConfiguration/Header.vue";
import MTConfigurationService from "@/services/MTConfigurationService";
import { POGRNField } from "@/Enum/MTConfig";
import Snackbar from "@/components/common/Snackbar.vue";
import CompanySettingServics from "@/services/CompanySettingServics";
@Component({
  components: {
    DataTable,
    Snackbar,
    ConfigurationHeader,
  },
})
export default class ConfigurationSetting extends Vue {
  private totalItems: number = 3;
  private buttonText1: string = "Save Changes";
  private buttonText2: string = "Cancel Changes";
  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private snackColor: string = "red";
  private fields: any = {};
  private nomenclature: any = {};
  private companyUsesCustomerLevelTertiaryfield: any = false;
  private companyUsesTertiaryOffTakeManualfield: any = false;
  public TertiarySales: string = "Tertiary Sales";
  public StockInward: string = "Stock Inward";
  private componentKey: number = 0;
  private loading: any = false;
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Field",
      value: "field",
      sortable: false,
      default: "",
    },
    {
      text: "Mandatory",
      value: "Mandatory",
      sortable: false,
      default: "",
    },
    {
      text: "Visible",
      value: "Visible",
      sortable: false,
      default: "",
    },
  ];
  private tableValues: Array<IConfiguration> = [
    {
      field: POGRNField.PONumber,
      Mandatory: false,
      Visible: false,
    },
    {
      field: POGRNField.PODate,
      Mandatory: false,
      Visible: false,
    },

    {
      field: POGRNField.InvoiceImage,
      Mandatory: false,
      Visible: false,
    },

    {
      field: POGRNField.GRN,
      Mandatory: true,
      Visible: true,
    },
  ];

  private table2Values: Array<IConfiguration> = [
    {
      field: POGRNField.NoOfCustomersAttended,
      Mandatory: false,
      Visible: false,
    },
    {
      field: POGRNField.NoOfCustomersConverted,
      Mandatory: false,
      Visible: false,
    },
  ];

  private table3Values: Array<IConfiguration> = [
    {
      field: POGRNField.CaptureImage,
      Mandatory: false,
      Visible: false,
    },
  ];

  private totalRows: Array<IConfiguration> = [];
  private POGRNFields: Array<any> = [
    { key: "1", value: "PO Number" },
    { key: "2", value: "PO Date" },

    { key: "4", value: "Invoice Image" },
    { key: "3", value: "GRN" },
    { key: "5", value: "Number of customers attended" },
    { key: "6", value: "Number of customers converted" },
    { key: "7", value: "Image Capture"}
  ];

  MarkVisible(Values: IConfiguration) {
    if (Values.Mandatory == true) {
      Values.Visible = true;
    }
  }

  MarkVisible2(Values: IConfiguration) {
    if (Values.field == 5) {
      this.table2Values[1].Mandatory = this.table2Values[0].Mandatory;
    } else {
      this.table2Values[0].Mandatory = this.table2Values[1].Mandatory;
    }
    if (Values.Mandatory == true) {
      Values.Visible = true;
    }
    if (
      this.table2Values[0].Mandatory == false ||
      this.table2Values[1].Mandatory == false
    ) {
      this.table2Values[1].Mandatory = false;
      this.table2Values[0].Mandatory = false;
      this.table2Values[1].Visible = false;
      this.table2Values[0].Visible = false;
    }
    if (
      this.table2Values[0].Mandatory == true ||
      this.table2Values[1].Mandatory == true
    ) {
      this.table2Values[1].Mandatory = true;
      this.table2Values[0].Mandatory = true;
      this.table2Values[1].Visible = true;
      this.table2Values[0].Visible = true;
    }

    this.componentKey += 1;
  }
  ChangeMandatory(value: IConfiguration) {
    if (value.Visible == false) {
      value.Mandatory = false;
    }
  }
  ChangeMandatory2(value: IConfiguration) {
    if (value.field == 5) {
      this.table2Values[1].Visible = this.table2Values[0].Visible;
    } else {
      this.table2Values[0].Visible = this.table2Values[1].Visible;
    }
    if (value.Visible == false) {
      value.Mandatory = false;
    }

    if (
      this.table2Values[0].Visible == false ||
      this.table2Values[1].Visible == false
    ) {
      this.table2Values[1].Mandatory = false;
      this.table2Values[0].Mandatory = false;
      this.table2Values[1].Visible = false;
      this.table2Values[0].Visible = false;
    }
    if (
      this.table2Values[0].Visible == true ||
      this.table2Values[1].Visible == true
    ) {
      this.table2Values[1].Mandatory = true;
      this.table2Values[0].Mandatory = true;
      this.table2Values[1].Visible = true;
      this.table2Values[0].Visible = true;
    }
    this.componentKey += 1;
  }
  public async getPOGRNFields() {
    let that = this;
    MTConfigurationService.getPOGRNFields()
      .then((res: any) => {
        that.fields = res.data;
        that.UpdateValues(that.fields);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  public async getCompanyNomenclatures() {
    let that = this;
    CompanySettingServics.getCompanyNomenclatures()
      .then((res: any) => {
        that.nomenclature = res.data;
        this.TertiarySales = that.nomenclature.TertiarySales.displayName;
        this.StockInward = that.nomenclature.StockInward.displayName;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  public async CompanyUsesCustomerLevelTertiary() {
    let that = this;
    CompanySettingServics.CompanyUsesCustomerLevelTertiary()
      .then((res: any) => {
        that.companyUsesCustomerLevelTertiaryfield = res.data.result;
        debugger
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  public async CompanyUsesTertiaryOffTakeManual() {
    let that = this;
    CompanySettingServics.CompanyUsesTertiaryOffTakeManual()
      .then((res: any) => {
        that.companyUsesTertiaryOffTakeManualfield = res.data.result;
        debugger
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  UpdateValues(fields: any) {
    fields.forEach((i: any, index: number) => {
      this.tableValues.forEach((j: any, index: number) => {
        if (i.field == j.field) {
          j.Visible = i.visible;
          j.Mandatory = i.mandatory;
        }
      });
      this.table2Values.forEach((j: any, index: number) => {
        if (i.field == j.field) {
          j.Visible = i.visible;
          j.Mandatory = i.mandatory;
        }
      });
      this.table3Values.forEach((j: any, index: number) => {
        if (i.field == j.field) {
          j.Visible = i.visible;
          j.Mandatory = i.mandatory;
        }
      });
    });
  }
  public CancelChanges() {
    this.getPOGRNFields();
  }
  public submitSetting() {
    this.totalRows = this.tableValues.concat(this.table2Values, this.table3Values);
    MTConfigurationService.POGRNSettingUpdate(this.totalRows)
      .then((res: any) => {
        this.snackColor = "green";
        this.snackBarMessage = "Saved Successfully!";
        this.snackbar = true;
        return;
      })
      .catch((err: any) => {
        console.log(err);
        console.log(err);
        this.snackColor = "red";
        this.snackBarMessage = "Some error occured.";
        this.snackbar = true;
        return;
      });
  }
  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }
   mounted() {
    this.loading = true;
    this.CompanyUsesCustomerLevelTertiary();
    this.CompanyUsesTertiaryOffTakeManual();
    this.getCompanyNomenclatures();
    this.getPOGRNFields();
    this.loading = false;
  }
}
