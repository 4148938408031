






















    import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
    @Component({})
export default class ConfigurationHeader extends Vue {
    @Prop({required: true}) readonly btnText1!: string
    @Prop({required: true}) readonly btnText2!: string
    @Emit("callAction1")
    callAction1(){
        return;
    }
    @Emit("callAction2")
    callAction2(){
        return;
    }

}
