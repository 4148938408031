var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-divider'),_c('ConfigurationHeader',{attrs:{"btnText1":_vm.buttonText1,"btnText2":_vm.buttonText2},on:{"callAction1":_vm.submitSetting,"callAction2":_vm.CancelChanges}}),_c('v-divider'),_c('br'),(_vm.loading)?_c('div'):_c('div',[_c('div',{staticClass:"TopHeader"},[_c('span',{staticClass:"SubHeaderText"},[_vm._v(" "+_vm._s(_vm.StockInward)+" ")])]),_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableValues,"totalItems":_vm.totalItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
var headers = ref.headers;
return [(headers.value == 'Mandatory')?_c('span',[_c('v-checkbox',{attrs:{"color":"red"},on:{"click":function($event){return _vm.MarkVisible(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'Visible')?_c('span',[_c('v-checkbox',{on:{"click":function($event){return _vm.ChangeMandatory(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'field')?_vm._l((_vm.POGRNFields),function(i){return _c('span',{key:i.key},[(i.key == props[headers.value])?_c('div',[_vm._v(" "+_vm._s(i.value)+" ")]):_vm._e()])}):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}])}),_c('br'),(_vm.companyUsesTertiaryOffTakeManualfield && !_vm.companyUsesCustomerLevelTertiaryfield)?_c('div',{staticClass:"TopHeader"},[_c('span',{staticClass:"SubHeaderText"},[_vm._v(" "+_vm._s(_vm.TertiarySales)+" ")])]):_vm._e(),(_vm.companyUsesTertiaryOffTakeManualfield && !_vm.companyUsesCustomerLevelTertiaryfield)?_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.table2Values,"totalItems":_vm.totalItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
var headers = ref.headers;
return [(headers.value == 'Mandatory')?_c('span',[_c('v-checkbox',{key:_vm.componentKey,attrs:{"color":"red"},on:{"click":function($event){return _vm.MarkVisible2(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'Visible')?_c('span',[_c('v-checkbox',{key:_vm.componentKey,on:{"click":function($event){return _vm.ChangeMandatory2(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'field')?_vm._l((_vm.POGRNFields),function(i){return _c('span',{key:i.key},[(i.key == props[headers.value])?_c('div',[_vm._v(" "+_vm._s(i.value)+" ")]):_vm._e()])}):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}],null,false,39576335)}):_vm._e(),(_vm.companyUsesCustomerLevelTertiaryfield)?_c('div',{staticClass:"TopHeader"},[_c('span',{staticClass:"SubHeaderText"},[_vm._v(" Customer-wise tertiary ")])]):_vm._e(),(_vm.companyUsesCustomerLevelTertiaryfield)?_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.table3Values,"totalItems":_vm.totalItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
var headers = ref.headers;
return [(headers.value == 'Mandatory')?_c('span',[_c('v-checkbox',{key:_vm.componentKey,attrs:{"color":"red"},on:{"click":function($event){return _vm.MarkVisible2(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'Visible')?_c('span',[_c('v-checkbox',{key:_vm.componentKey,on:{"click":function($event){return _vm.ChangeMandatory2(props)}},model:{value:(props[headers.value]),callback:function ($$v) {_vm.$set(props, headers.value, $$v)},expression:"props[headers.value]"}})],1):(headers.value == 'field')?_vm._l((_vm.POGRNFields),function(i){return _c('span',{key:i.key},[(i.key == props[headers.value])?_c('div',[_vm._v(" "+_vm._s(i.value)+" ")]):_vm._e()])}):_c('span',{class:props[headers.value] == '' ? "blackLow--text" : ""},[_vm._v(" "+_vm._s(props[headers.value] == "" ? headers.default : props[headers.value])+" ")])]}}],null,false,39576335)}):_vm._e(),_c('Snackbar',{attrs:{"message":_vm.snackBarMessage,"snackbar":_vm.snackbar,"timeout":'1500',"color":_vm.snackColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TopHeader"},[_c('span',{staticClass:"HeaderText"},[_vm._v(" ModernTrade Fields For App ")])])}]

export { render, staticRenderFns }